import React from "react";
import AboutUsInfoCTA from "../../component/section/aboutUsInfoCTA";
import TeamTile from "../../component/aboutUs/teamTile";

const AboutPage = () => {
  const teamMembers = [
    {
      name: "Dustin Gordon",
      role: "Founder/Director",
      description:
        "Our Director, Founded True Blue Water Exchange 2019 after multiple years in the Real Estate & Water Broking/Agri Sectors.\n\nA seasoned water broker and sales professional with over a decade of experience in the industry. Dustin has a deep understanding of Australian water rights, rules, regulations and a proven track record of success in negotiations. With extensive experience in building relationships, identifying customer needs, and closing deals.  Dustin has continued to lead the business to the highest of standards over the years, building a highly skilled team spanning 4 states of Australia. Dustin has a genuine care for the agri business sector and it’s people and is in for the long haul for years to come.",
      email: "dustin@tbwater.com.au",
      mobile: "0410 468 191",
      imgUrl:
        "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/Dustin.jpg",
    },
    {
      name: "Steve Ling",
      role: "Chief Operating Officer",
      description:
        "With a background in Business Development Management and Sales for the past twenty-five years, Steve has a solutions-based focus for all his clients whilst ensuring the ultimate customer experience. Knowledgeable in all aspects of the MDBA Water Market, Steve will ensure he puts his client’s first. Outside of the business, Steve is heavily involved in soccer at community level and is chairman of his club.",
      email: "steve@tbwater.com.au",
      mobile: "0400 756 927",
      imgUrl: "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/STEVE.jpg",
    },
    {
      name: "Peter Gordon",
      role: "Senior Water Broker",
      description:
        "Peter J. Gordon has been in the Real estate & Water Brokering Business for 25 Years running the Gordon Agencies PL & now Partnering with True Blue Water Exchange as a Broker, working from the Gordon Agencies Real Estate & True Blue Water Exchange Office, 671 Koorlong Avenue Irymple, Vic.\n\nReady to assist Clients for the Irrigation Water Enquires & Needs. Whether a Horticulturalist, Viticulture, Pasture Irrigator, or Investor, Peter can assist with your water requirements, experienced the Highs & Lows Of the Industry and can advise accordingly. Always acknowledges whether by phone, text, or email a consistent approach to the business of the day.",
      email: "peter@tbwater.com.au",
      mobile: "0417 380 688",
      imgUrl: "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/PETER.jpg",
    },
    {
      name: "Shaun Blythman",
      role: "Water Broker, Market Specialist",
      description:
        "A highly skilled sales professional with the knowledge and expertise to effectively manage water resources and broker deals. His exceptional communication and interpersonal skills, combined strategic thinking and attention to detail, makes him a valuable asset to the TBWE team. Whether working with farmers, or other stakeholders, Shaun has consistently delivered outstanding results and is committed to maximizing value for his clients.",
      email: "shaun@tbwater.com.au",
      mobile: "0447 502 134",
      imgUrl:
        "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/tb-team-shaun.jpg",
    },
    {
      name: "Stephanie Clarke",
      role: "Water Broker, Market Specialist",
      description:
        "Steph brings a unique blend of skills to the True Blue Water team. Based in our NSW office in Coleambally in the Riverina, she offers a wealth of expertise as a Chartered Accountant, with a deep understanding of the challenges businesses face. Her strong professional network ensures clients receive expert advice on transactions. With experience in an irrigation corporation, Steph provides valuable insights into the seasonal and industry issues affecting the agricultural community. Dedicated to meeting her clients' needs, she delivers thoughtful and reliable guidance in the ever-changing landscape of water resources.",
      email: "steph@tbwater.com.au",
      mobile: "0480 091 692",
      imgUrl: "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/STEPH.jpg",
    },
    {
      name: "Ben Tankard",
      role: "Water Broker, Market Specialist",
      description:
        "A relational water broker who has a genuine understanding of the farming industry having grown up on a dried fruit property giving Ben a unique view of his clients circumstances. Ben is a strategic thinker with a hardworking nature who leaves no stone unturned in the mission to achieve best results for his clients. He prides himself on building long lasting relationships and having a deep knowledge of water markets.",
      email: "ben@tbwater.com.au",
      mobile: "0447 496 623",
      imgUrl: "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/BEN.jpg",
    },
    {
      name: "Reeza Vawda",
      role: "Water Broker, Market Specialist",
      description:
        "Reeza is an up and coming water broker with a commitment to providing clients with reliable and strategic support. With a background in sales, he brings an in-depth understanding of market trends and water rights, ensuring clients navigate the complexities of water markets with confidence. Known for his hard working nature and dedication, Reeza delivers tailored solutions that align with each client's goals, helping them make informed decisions in an ever-evolving water market.",
      email: "reeza@tbwater.com.au",
      mobile: "0483 935 353",
      imgUrl:
        "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/defaultProfileImage.png",
    },
    {
      name: "Pam Faderon",
      role: "Sales Support/Administration",
      description:
        "With over 13 years in administration and marketing, Pam is a dedicated and skilled professional at True Blue Water Exchange. She efficiently manages deals and keeps clients informed throughout their buying or selling journey. Known for her commitment and hard work, Pam plays a key role in ensuring top-tier service, consistently staying updated on market movements to help the team seize new opportunities in agribusiness and water trading.",
      email: "admin@tbwater.com.au",
      imgUrl:
        "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/tb-team-pam.jpg",
    },
    {
      name: "Ebony McHugh",
      role: "Accounts & Administration",
      description:
        "Born and raised in Shepparton, Victoria, Ebony has a solid background in customer service and team management. Recently relocating to Mildura, Ebony is now pursuing studies in accounting and bookkeeping to expand her financial expertise. Joining True Blue Water Exchange in October as an Accounts and Administration Specialist, Ebony brings a proactive and detail-oriented approach to financial and administrative support, ensuring clients receive exceptional service in every interaction.",
      email: "ebony@tbwater.com.au",
      mobile: "1300 382 092",
      imgUrl: "https://tbwe-cdn.s3.ap-southeast-2.amazonaws.com/Team/EBONY.jpg",
    },
  ];

  return (
    <div className="about-page">
      <AboutUsInfoCTA />
      <div className="center">
        <h2 style={{ padding: "40px", fontWeight: "700", fontSize: "40px" }}>
          Meet the Team
        </h2>
      </div>
      <div className="center">
        <div className="team-members">
          {teamMembers.map((member, index) => (
            <TeamTile
              key={index}
              name={member.name}
              role={member.role}
              description={member.description}
              email={member.email}
              twitter={member.twitter}
              facebook={member.facebook}
              phone={member.mobile}
              imgUrl={member.imgUrl}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
